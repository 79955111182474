import { Injectable, Output, EventEmitter } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import {
  ActivatedRoute,
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AppService } from "./app.service";
import { MatSnackBar } from "@angular/material";
import { UserLoginI } from "../Models/user-login.model"
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";


@Injectable({
  providedIn: "root",
})
export class AuthService implements CanActivate {
  readonly BaseURI = environment.Url;
  readonly roles=['USER','SUPER','ADMINJUNIOR','ADMIN']
  @Output() onCustomerLoggedIn: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private appService: AppService,
    private cookieService: CookieService,
  ) { }
  canActivate(activeRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (activeRoute.routeConfig.path === "new-user" && this.appService.user.token) {
      return true;
    }
    if (this.appService.user.token && this.appService.user.role && this.appService.user.orgName) {
      // logged in so return true
      return true;
    }
    if (this.appService.user.token) {
      this.router.navigate(["/login/new-user"]);
      return false;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"]);
    return false;
  }

  formModel = this.fb.group({
    UserName: ["", Validators.required],
    Email: ["", Validators.email],
    FullName: [""],
    Passwords: this.fb.group(
      {
        Password: ["", [Validators.required, Validators.minLength(4)]],
        ConfirmPassword: ["", Validators.required],
      },
      { validator: this.comparePasswords }
    ),
  });

  comparePasswords(fb: FormGroup) {
    let confirmPswrdCtrl = fb.get("ConfirmPassword");
    //passwordMismatch
    //confirmPswrdCtrl.errors={passwordMismatch:true}
    if (
      confirmPswrdCtrl.errors == null ||
      "passwordMismatch" in confirmPswrdCtrl.errors
    ) {
      if (fb.get("Password").value != confirmPswrdCtrl.value)
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      else confirmPswrdCtrl.setErrors(null);
    }
  }

  register(user: any): Observable<any> {
    var token = "Bearer " + this.appService.user.token;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: token,
      }),
    };
    return this.http.post<any>(this.BaseURI + "Account/_signup", user, httpOptions).pipe(
      catchError(err => {
        if (err.status == '401') {
          this.appService.clear();
          return throwError(err);
        }
      })
  );
    // var reqHeader = new HttpHeaders({ "No-Auth": "True" });
    // return this.http.post<any>(this.BaseURI + "Account/_signup", user, {
    //   headers: reqHeader,
    // });
  }

  sendPasswordResetEmail(email: string) {
    var reqHeader = new HttpHeaders({ "No-Auth": "True" });
    return this.http.get(this.BaseURI + "Account/sendPasswordResetEmail/" + email, {
      headers: reqHeader,
    });
  }

  resetPassword(data: any) {
    var reqHeader = new HttpHeaders({ "No-Auth": "True" });
    return this.http.post(this.BaseURI + "Account/_resetpassword", data, {
      headers: reqHeader,
    });
  }

  login(formData) {
    var data = this.http.post(this.BaseURI + "token", formData);
    data.subscribe(

      (res: any) => {
        localStorage.setItem("userId", res.userId);
        localStorage.setItem("token", res.token);
        if (res.role) {
          localStorage.setItem("role", res.role);
        }
        localStorage.setItem("fullName", res.fullName);
        if (res.orgName) {
          localStorage.setItem("orgName", res.orgName);
        }
        this.appService.user = new UserLoginI(this.appService.jwtDecoderService, this.cookieService);
        if (res.OrgId == environment.ELBIT_ID || res.role == 'ADMIN' || res.role == 'ADMINJUNIOR' && res.SuperUserOrgs.includes(environment.ELBIT_ID) || res.role == 'ADMINJUNIOR' && res.OrgId == environment.ELBIT_ID || res.role == 'SUPER' && res.SuperUserOrgs.includes(environment.ELBIT_ID)) {
        //if (res.orgId == environment.ELBIT_ID || res.role == 'ADMIN' ||res.role == 'ADMINJUNIOR' || res.role == 'SUPER' && res.superUserOrgs.includes(environment.ELBIT_ID)) {
          this.appService.user.showArchive = true
        } else {
          this.appService.user.showArchive = false
        }
        localStorage.setItem("showArchive", this.appService.user.showArchive.toString());
      //   if (res.orgId == environment.ALIGN_TECHNOLOGY_ID) {
      //     this.appService.user.checkQuantitiy = false
      //   } else {
      //     this.appService.user.checkQuantitiy = true
      //   }
      //  localStorage.setItem("checkQuantitiy", this.appService.user.checkQuantitiy.toString());
        //event fire
        // this.onCustomerLoggedIn.emit(res.token);
        this.appService.showRouter=false;
        this.appService.connectWebSocket()
          this.router.navigateByUrl("/home");
          // this._snackBar.open(" שלום " + this.appService.user.fullName, "התחברת בהצלחה", {
          //   duration: 2000,
          // });

        // window.location.reload();
      },
      (err) => {
        if (err.status == 401)
          this._snackBar.open("שם משתמש ו/או סיסמה שגויים", "לא ניתן להתחבר", {
            duration: 2000,
          });
        else console.log(err);
      }
    );
  }
  SetGloabl
  LogOut() {
    this.appService.hubConnection.stop();
     this.appService.user.clear();
    this.appService.user = new UserLoginI(this.appService.jwtDecoderService, this.cookieService);
    this.appService.showRouter = true;
    this.router.navigate(["/login"]);
  }  
}