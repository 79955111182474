import { Injectable, EventEmitter } from '@angular/core';
import { UserLoginI } from '../Models/user-login.model';
import { environment } from '../../environments/environment';
// import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import { LogService } from './log.service';
import { Router } from '@angular/router';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
// import * as signalR from '@aspnet/signalr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtDecoderService } from './jwt-decoder.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public hubConnection: HubConnection;
  isLoad: boolean = false;
  user: UserLoginI;
  showRouter: boolean = false;
  loadMissionEvent: EventEmitter<number> = new EventEmitter();
  loadComplaintEvent: EventEmitter<number> = new EventEmitter();
  jwtDecoderService: JwtDecoderService;
  constructor(
    private logService: LogService,
    private router: Router,
    private http: HttpClient,
    jwtDecoderService: JwtDecoderService,
    private cookieService: CookieService
  ) { this.jwtDecoderService = jwtDecoderService; }
  loadMission() {
    this.loadMissionEvent.emit(1);
  }
  getLoadMissionEmitter() {
    return this.loadMissionEvent;
  }
  loadComplaint(){
    this.loadComplaintEvent.emit(1)
  }
  getLoadComplaintEmitter() {
    return this.loadComplaintEvent;
  }
  connectWebSocket() {
    if (this.user.token) {
      // let builder = new HubConnectionBuilder();
      // as per setup in the startup.cs
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(environment.baseUrl + "hubs/missions", {
          // skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets && signalR.HttpTransportType.LongPolling,
          accessTokenFactory: () => this.user.token,
        })
        .withAutomaticReconnect()
        // .configureLogging(signalR.LogLevel.Debug)
        .build();

      // this.hubConnection.serverTimeoutInMilliseconds = 120000;
      this.hubConnection.start()
        .then(() => {
          console.log("Connected from app component!");
          this.showRouter = true;
        })
        .catch((err) => {
          console.log("fail to connect " + err);
          this.showRouter = true;
          if (err.status == '401') {
            this.clear();
          }
          // else {
          //   this.logService.Error({
          //     message: err.message,
          //     function: "AppService:ConnectWebSocket:start",
          //     data: this.user.userId
          //   });
          // }
        });







      this.hubConnection.onclose((reason) => {
        console.log(reason.message);
        console.log('Connection disconnected');
        setTimeout(function () {
          let builder = new HubConnectionBuilder();
          // as per setup in the startup.cs
          this.hubConnection = builder
            .withUrl(environment.baseUrl + "hubs/missions", {
              accessTokenFactory: () => this.user.token,
            })
            .build();


          this.hubConnection.start()
            .then(() => {
              console.log("Connected from app component after closed!");
              this.showRouter = true;
            })
            .catch((err) => {
              this.showRouter = true;
              if (err.status == '401') {
                this.clear();
              }
            });
        }, 5000); // Restart connection after 5 seconds.




        // if (reason) {
        //   this.logService.Error({
        //     message: reason.message,
        //     function: "AppService:ConnectWebSocket:onclose",
        //     data: this.user.userId
        //   });
        // }
        // else {
        //   this.logService.Error({
        //     message: "without reaseon",
        //     function: "AppService:ConnectWebSocket:onclose",
        //     data: this.user.userId
        //   });
        // }
        // this.user.clear();
        // this.user = new UserLoginI();
        // this.showRouter = true;
        // this.router.navigate(["/login"]);
      });

    }
    else {
      this.showRouter = true;
    }
  }
  // post(url, data,callback:any=null) {
  //   let httpOptions = this.createRequestHeader();
  //   return this.http.post(environment.Url + url, data, httpOptions).subscribe
  //   ((data: any) => {
  //     console.log("post");
  //     this.isLoad = false;
  //     if(callback){
  //       callback();
  //     }
  //   }, (error) => {
  //     this.handleError(error);
  //   });  
  // }
  // put(url, data,callback:any=null) {
  //   let httpOptions = this.createRequestHeader();
  //   return this.http.put(environment.Url + url, data, httpOptions).subscribe
  //   ((data: any) => {
  //     console.log("get");
  //     this.isLoad = false;
  //     if(callback){
  //       callback();
  //     }
  //   }, (error) => {
  //     this.handleError(error);
  //   });  
  // }
  // get(url: string,callback:any=null) {
  //   this.isLoad = true;
  //   let httpOptions = this.createRequestHeader();
  //  this.http.get(environment.Url+ url, httpOptions).subscribe
  //   ((data: any) => {
  //     console.log("get");
  //     this.isLoad = false;
  //     if(callback){
  //       callback();
  //     }
  //   }, (error) => {
  //     this.handleError(error);
  //   });  
  // }

  // delete(url: string,callback:any=null) {
  //   this.isLoad = true;
  //   let httpOptions = this.createRequestHeader();
  //   return this.http.delete(environment.Url + url,httpOptions).subscribe
  //   ((data: any) => {
  //     console.log("get");
  //     this.isLoad = false;
  //     if(callback){
  //       callback();
  //     }
  //   }, (error) => {
  //     this.handleError(error);
  //   });  
  // }
  // updateIsload() {
  //   this.isLoad = true;
  // }
  //   private handleError(err) {
  //     this.isLoad = false;
  //     if (err.status == 400 || err.status == 500)
  //     this._snackBar.open(
  //       "אנא נסה שוב או פנה למנהל המערכת",
  //       "שגיאה",
  //       {
  //         duration: 2000,
  //       }
  //     );
  //     if (err.status == 401) {
  //       this.dialogRef.closeAll();
  //       this.user.clear();
  //       this.user = new UserLoginI();
  //       this.router.navigate(["/login"]);
  //     }
  //     return "error";
  //   }
  //   private createRequestHeader() {

  //     let httpOptions = {
  //       headers: new HttpHeaders({
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + this.user.token,
  //       })
  //   }
  //   return httpOptions;
  // }
  clear() {
    this.user.clear();
    this.user = new UserLoginI(this.jwtDecoderService, this.cookieService);
    this.router.navigate(["/login"]);
  }
}