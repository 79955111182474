import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppService } from '../Services/app.service';
import { MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { MessageComponent } from '../components/message/message.component';

@Injectable()
export class CatchErrorInterceptor implements HttpInterceptor {
  constructor(private appService: AppService, private dialog: MatDialog,) {

  }
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(httpRequest).pipe(
      catchError(err => {
      console.log(err.message)
      if (err.status == '401') {
        this.appService.clear();
      }
      else {
        if(err.status == '409')
        {
          this.openMessage({ message: "מייל זה כבר קיים במערכת", isWarning: true });
        }
        else{
            this.openMessage({ message: "ארעה שגיאה , פנה למנהל המערכת", isWarning: true });
        }
      }
      return throwError(err);
    })
    );
  }
  openMessage(data) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = "35vw";
    dialogConfig.height = "40vh";
    dialogConfig.disableClose = true;
    dialogConfig.data = data;
    dialogConfig.panelClass = ['dialogMessage']
    this.dialog.open(MessageComponent, dialogConfig);
  }
}