import { Component, OnInit } from "@angular/core";
import { AppService } from "./Services/app.service";
import { UserLoginI } from './Models/user-login.model';
import {TranslateService} from '@ngx-translate/core';
import { AuthService } from "./Services/auth.service";
import { CookieService } from "ngx-cookie-service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {

  title = "ServichCoreApi";
  ngOnInit() {
    this.appService.user = new UserLoginI(this.appService.jwtDecoderService, this.cookieService);
    this.appService.connectWebSocket();
  }

  constructor(
    translate: TranslateService,
    public appService: AppService,
    public authService:AuthService,
    private cookieService: CookieService
  ) { 
    translate.setDefaultLang('he');

         // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate .use('he');
  }
}
